<template>
    <div>
      <v-app :style="colorVapp">
        <onebox_toolbar_inboxoutbox
          :quicksearch="true"
          :parentfolder="parentfolder"
          @inputdata="filteredItems"
          @loadfile="getSourceSystem()"
          @callstorage="loadstorage()"
        ></onebox_toolbar_inboxoutbox>
        <v-content>
          <!-- <v-divider></v-divider> -->
          <v-overlay :value="processloader" absolute :color="color.BG" dark>
            <v-progress-circular indeterminate size="64" :color="color.theme"></v-progress-circular>
            <br />
            <br />
            <span :style="colorProgress">&nbsp; &nbsp; loading</span>
          </v-overlay>
          <v-list nav :color="color.BG">
            <v-list-item class="text-left">
              <v-list-item-avatar class="text-center">
                <v-avatar :color="color.theme" size="35">
                  <v-icon dark size="20">{{defaulticon}}</v-icon>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <span :style="headerPage">{{ Labeldata }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-card class="elevation-0" :color="color.BG" id="thiscontainer_outboxsystem">
            <v-card-text class="pa-0">
              <v-container fluid>
                <v-layout row wrap justify-center>
                  <v-flex lg12 class="ma-2 ml-4 mr-4" v-if="loaddataprogress === true">
                    <div class="text-center">
                      <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                    </div>
                  </v-flex>
                  <v-flex lg12 class="ma-4" v-else>
                    <v-layout row wrap class="pl-3 pr-3">
                      <v-flex lg2 xs12>
                        <v-select
                          prepend-inner-icon="sort"
                          hide-details
                          solo
                          dense
                          v-model="size"
                          :items="listsize"
                          class="text-center"
                          :item-color="color.theme"
                          :color="color.theme"
                        >
                          <template v-slot:item="{ item }">
                            <div class="text-center">
                              <span style="font-size: 17px;">{{ item }}</span>
                            </div>
                          </template>
                          <template v-slot:selection="{ item }">
                            <div class="text-center">
                              <span style="font-size: 17px;">{{ item }}</span>
                            </div>
                          </template>
                        </v-select>
                        <br />
                      </v-flex>
                      <v-spacer></v-spacer>
                      <v-flex lg1 xs12 mb-4 d-flex justify-end v-if="$route.query.type === '2'">
                        <v-btn rounded dark :color="color.theme" @click="opendialogmanageheader = true">{{ $t("myinboxPage.settingHeader.setting") }}</v-btn>                      
                      </v-flex>
                      <v-flex lg1 xs12 mb-4 d-flex justify-end v-if="$route.query.type === '2'">
                        <v-btn rounded dark :color="color.theme" @click="opendialogresendfile = true">{{ $t("resendfile.resend") }}</v-btn>                      
                      </v-flex>                    
                    </v-layout>
                    <div v-if="resolutionScreen <= 500">
                      <v-list two-line class="mb-6 pb-6 elevation-1">
                        <v-list-item v-if="paginatedData.length === 0">
                          <v-list-item-content class="text-center">
                            <v-list-item-title v-text="$t('myoutboxPage.Nodata')"></v-list-item-title>
                            <v-list-item-subtitle></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          v-else
                          v-for="item in paginatedData"
                          :key="item.title"
                          @dblclick="gotoSourceSystemIndex(item.system,item.system_id)"
                          @click="gotoSourceSystemIndex(item.system,item.system_id)"
                          style="cursor: pointer;"
                        >
                          <v-list-item-avatar>
                            <v-icon large :color="color.theme" style="font-weight: 100">developer_board</v-icon>
                          </v-list-item-avatar>
  
                          <v-list-item-content @dblclick="gotodirectory()">
                            <v-list-item-title v-text="item.system"></v-list-item-title>
                            <v-list-item-subtitle></v-list-item-subtitle>
                          </v-list-item-content>
                            <v-list-item-action>
                            <v-chip
                              v-if=" item.sum_receive > 0"
                              class="ma-2"
                              color="red"
                              text-color="white"
                            >{{ item.sum_receive}}</v-chip>
                          </v-list-item-action>
                        </v-list-item>
                        <!-- 
                        <v-divider inset></v-divider>-->
                      </v-list>
                    </div>
  
                    <div v-else>
                      <v-data-table
                        :headers="headers"
                        :items="systemdirectory"
                        item-key="name"
                        :no-data-text="$t('myoutboxPage.Nodata')"
                        :show-select="false"
                        :search="search"
                        class="elevation-1"
                        item-selected
                        :page.sync="page"
                        :items-per-page="size"
                        :hide-default-footer="true"
                        @page-count="pageCount = $event"
                      >
                        <!-- <template v-slot:header.system_type="{ header }">
                          <span
                          style="color: #1565C0; font-weight: 600; font-size:15px"
                          >{{ header.text }}</span>
                        </template> -->
                        <template v-slot:[`header.system`]="{ header }">
                          <span
                            :style="headerTable"
                          >{{ $t(header.text) }}</span>
                        </template>
  
                        <template v-slot:item="props">
                          <tr
                            @click="gotoSourceSystemIndex(props.item.system, props.item.system_id)"
                            style="cursor: pointer;"
                          >
                            <td class="text-center">
                              <v-icon x-large :color="color.theme" style="font-weight: 100">developer_board</v-icon>
                            </td>
                            <td>{{ props.item.system | subStr}}</td>
                             <td class="text-right">
                              <v-chip
                                v-if="props.item.sum_receive > 0"
                                class="ma-2"
                                color="red"
                                text-color="white"
                              >{{ props.item.sum_receive}}</v-chip>
                            </td>
                          </tr>
                        </template>
                        <!-- <template v-slot:body="{ items }">
                        <tbody>
                          <tr
                            v-for="(item, index) in items"
                            :key="index"
                            @contextmenu="test($event,item)"
                            @dblclick="gotoSourceSystemIndex(item.system)"
                            style="cursor: pointer;"
                          >
                            <td class="text-center">
                              <v-icon x-large color="primary" style="font-weight: 100">move_to_inbox</v-icon>
                            </td>
                            <td>{{ item.system}}</td>
                          </tr>
                        </tbody>
                        </template>-->
                      </v-data-table>
                    </div>
                    <br />
                    <v-layout row wrap justify-center>
                      <v-flex xs11 lg11 v-if="systemdirectory.length > 0">
                        <v-pagination v-model="page" :length="pageCount || 0" :color="color.theme"></v-pagination>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
  
            <dialogresendfile
              :show="opendialogresendfile"
              @closedialog="opendialogresendfile = false"
            ></dialogresendfile>
            <dialogmanageheader
              :show="opendialogmanageheader"
              @closedialog="opendialogmanageheader = false"
            ></dialogmanageheader>          
  
          </v-card>
        </v-content>
      </v-app>
    </div>
  </template>
  
  <script>
  import Swal from "sweetalert2/dist/sweetalert2.js";
  import { mapState, mapGetters } from "vuex";
  import gbfGenerate from "@/globalFunctions/generateAuthorize";
  // const onebox_toolbar = () => import("../components/layout/layout-toolbar-new");
//   import onebox_toolbar from "../components/layout/layout-toolbar-new";
  import onebox_toolbar_inboxoutbox from "../components/layout/layout-inboxoutbox-toolbar";
  const dialogmanageheader = () => import("../components/optional/dialog-manageheaderoutbox.vue");
  const dialogresendfile = () => import("../components/optional/dialog-resendfileoutbox.vue");
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000
  });
  export default {
    name: "myoutbox_system",
    components: { onebox_toolbar_inboxoutbox, dialogresendfile, dialogmanageheader },
    data: function() {
      return {
        opendialogmanageheader: false,
        opendialogresendfile: false,
        search: '',
        defaulticon: "",
        page: 1,
        size: 50,
        listsize: [20, 50, 100],
        nodata: this.$t("myoutboxPage.Nodata"),
        loaddataprogress: true,
        systemdirectory: [],
        headers: [
          {
            text: "#",
            align: "left",
            value: "file_type",
            width: "7%",
            sortable: false
          },
          {
            text: "myoutboxPage.HeaderSourceSystem.systemname",
            align: "left",
            value: "system",
            width: "83%",
            sortable: false
          },
                  {
            text: "",
            align: "left",
            value: "sum_receive",
            width: "10%",
            sortable: false
          }
        ]
      };
    },
    computed: {
      ...mapState(["username", "authorize", "account_active", "service","color"]),
      ...mapState({ processloader: "loading" }),
      ...mapGetters([
        "dataUsername",
        "dataAuthorize",
        "dataAccountActive",
        "dataAccesstoken",
        "dataBusinessProfile",
        "dataCitizenProfile",
        "dataDepartmentAccessId",
        "dataAccountId",
        "dataStorageUsage",
        "dataStorageMax"
      ]),
      parentfolder() {
        return this.dataAccountActive["directory_id"];
      },
      Labeldata() {
        let companyname = ''
        if (this.$route.query.type === "2") {
          this.defaulticon = "business";
          this.getSourceSystem();
          if(this.$t('default') === 'th'){
            if (this.$route.query.nameth === undefined || this.$route.query.nameth === null || this.$route.query.nameth === ""){
              companyname = this.dataAccountActive['business_info']['first_name_th'] || "-"
            }else{
              companyname = this.$route.query.nameth
            }
          }else{
            if (this.$route.query.nameen === undefined || this.$route.query.nameen === null || this.$route.query.nameen === ""){
               companyname = this.dataAccountActive['business_info']['first_name_eng'] || "-"
            }else{
              companyname = this.$route.query.nameen
            }
          }
          return this.$t("toolbar.myoutbox.Businessname") + ' ' + companyname;
        } else {
          this.defaulticon = "face";
          this.getSourceSystem();
          return this.$t("toolbar.myoutbox.Personalname");
        }
      },
      resolutionScreen() {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return 220;
          case "sm":
            return 400;
          case "md":
            return 500;
          case "lg":
            return 600;
          case "xl":
            return 800;
        }
      },
      pageCount: {
        get() {
          let l = this.systemdirectory.length;
          let s = this.size;
          return Math.ceil(l / s);
        },
        set(newName) {
          return newName;
        }
      },
      paginatedData() {
        const start = (this.page - 1) * this.size;
        const end = start + this.size;
        return this.systemdirectory.slice(start, start + this.size);
      },
         showdatafilter() {
          // console.log(search);
          return this.systemdirectory.filter(item => {
          return item.system.toLowerCase().indexOf(this.search.toLowerCase()) > -1
  
        })
      },headerTable(){
        return 'color:'+this.color.theme+';'+'font-weight: 600; font-size:15px;'
      },headerPage(){
        return 'color:'+this.color.theme+';'+'font-size: 18px; font-weight: 600;'
      },colorVapp(){
        return 'background:'+this.color.BG+';'
      },colorProgress(){
        return 'color:'+this.color.theme+';'
      },
    },
      filters: {
      subStr: function(string) {
        if(string == null || string == '' || string == undefined){
          return string
        }else{
          if (string.length > 30) return string.substring(0, 30) + "...";
        else return string;
        }
        
       }
    },
    methods: {
      filteredItems(search){
        this.search=search
      },
      loadstorage() {
        this.$store.dispatch("check_storage", this.dataAccountId).then(res => {});
      },
      getSourceSystem() {
        if (this.$route.query.type === "2") {
          if (
            this.$route.query.biz === undefined ||
            this.$route.query.biz === null ||
            this.$route.query.biz === ""
          ) {
            this.loadSourceSystem(
              this.dataAccountActive.business_info["business_id"],
              "2"
            );
          } else {
            this.loadSourceSystemV2(
              this.$route.query.biz,
              "2",
              this.$route.query.fromdate,
              this.$route.query.todate
            );
          }
        } else {
          this.loadSourceSystem(this.dataAccountId, "1");
        }
      },
  
      async loadSourceSystem(_accountid, _type) {
        this.loaddataprogress = true;
        let payload = {
          sender_id: _accountid,
          sender_type: _type
        };
        let auth = await gbfGenerate.generateToken();
        try {
          let response = await this.axios.post(
            process.env.VUE_APP_SERVICE_GET_OUTBOX + "/api/getmyoutbox",
            payload,
            { withCredentials: false ,
            headers: { Authorization: auth.code },
            }
          );
          this.loaddataprogress = false;
  
          if (response.data.status === "OK") {
            this.systemdirectory = response.data.result;
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage
            });
          }
        } catch (ex) {
          this.loaddataprogress = false;
        }
      },
  
      async loadSourceSystemV2(_accountid, _type, _fromdate, _todate) {
        this.loaddataprogress = true;
        let payload = {
          sender_id: _accountid,
          sender_type: _type,
          startdate: this.formatDate(_fromdate),
          enddate: this.formatDate(_todate)
        };
        try {
          let response = await this.axios.post(
            process.env.VUE_APP_SERVICE_GET_OUTBOX + "/api/v2/getmyoutbox",
            payload,
            { withCredentials: false }
          );
          console.log(response);
          this.loaddataprogress = false;
  
          if (response.data.status === "OK") {
            this.systemdirectory = response.data.result;
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage
            });
          }
        } catch (ex) {
          this.loaddataprogress = false;
        }
      },
  
      gotoSourceSystemIndex(_systemname,_systemid) {
        this.$router.push({
          path: "/myoutbox-sourcesystem",
          query: {
            type: this.$route.query.type,
            sourcesystem: _systemname,
            systemid: _systemid,
            biz: this.$route.query.biz,
            fromdate: this.$route.query.fromdate,
            todate: this.$route.query.todate,
            nameen: this.$route.query.nameen,
            nameth: this.$route.query.nameth
          }
        });
      },
      formatDate(date) {
        if (!date) return "";
        const [year, month, day] = date.split("-");
        return `${year}${month}${day}`;
      }
    },
    mounted() {
      // this.getSourceSystem()
    }
  };
  </script>
  
  <style>
  #thiscontainer_outboxsystem {
    height: calc(101vh - 180px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -moz-overflow-scrolling: touch;
    -ms-overflow-scrolling: touch;
   
  }
  </style>